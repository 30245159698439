import Beian from "./beian.png";
const Index = (props: any) => {
  return (
    <>
      <p style={{ width: "600px", margin: "0 auto", padding: "20px 0" }}>
        网站正在建设中……
      </p>
      <div style={{ width: "600px", margin: "0 auto", padding: "20px 0" }}>
        <a
          target="_blank"
          href="https://beian.miit.gov.cn/"
          style={{
            display: "inline-block",
            textDecoration: "none",
            height: "20px",
            lineHeight: "20px",
          }}
        >
          <p
            style={{
              float: "left",
              height: "20px",
              lineHeight: "20px",
              margin: "0px 10px 0px 5px",
              color: "#939393",
            }}
          >
            苏ICP备2023013405号
          </p>
        </a>
        <a
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32010202011373"
          style={{
            display: "inline-block",
            textDecoration: "none",
            height: "20px",
            lineHeight: "20px",
          }}
        >
          <img src={Beian} style={{ float: "left" }} />
          <p
            style={{
              float: "left",
              height: "20px",
              lineHeight: "20px",
              margin: "0px 0px 0px 5px",
              color: "#939393",
            }}
          >
            苏公网安备 32010202011373号
          </p>
        </a>
      </div>
    </>
  );
};

export default Index;
